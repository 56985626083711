import React from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';

import { APV_PROJECT } from '@/referentials/routes';

import Layout from '@/components/Layout';
import PortfolioHeader from '@/components/Portfolio/PortfolioHeader';
import ContentSection from '@/components/Portfolio/ContentSection';
// import Testimonial from '@/components/Testimonial';
import NextProject from '@/components/Portfolio/NextProject';
import DoublePictures from '@/components/Portfolio/DoublePictures';

export default function Navys({ data }) {
  const { t } = useTranslation();

  const tags = [
    'data',
    'etl',
    'grafana',
    'dataViz',
    'python',
    'docker',
    'postgres',
  ];

  return (
    <Layout description={t('description')}>
      <PortfolioHeader
        title={t('title')}
        description={t('description')}
        websites={['defense.gouv.fr/marine', 'navys.eu']}
        tags={tags}
      />

      <DoublePictures
        pictures={[
          {
            source: data.dashboard,
            alt: 'Dashboard',
          },
          {
            source: data.logos,
            alt: 'Logos Marine Nationale & Navys',
          },
        ]}
      />
      <ContentSection title={t('paragraph1Title')} content={t('paragraph1')} />

      {/* <Testimonial /> */}

      <NextProject title={t('nextProject')} to={APV_PROJECT} />
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["navys", "general"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    dashboard: file(relativePath: { eq: "projects/navys/grafana.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    logos: file(relativePath: { eq: "projects/navys/marine-nationale.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
  }
`;
